import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import MenuMobile from "./MenuMobile"
import { FaBars } from "react-icons/fa"

const Header = ({ path }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  let pathStyle = ""
  path === "/tester"
    ? (pathStyle = "bg-transparent")
    : (pathStyle = "bg-mbr_green")

  const { site } = useStaticQuery(graphql`
    query {
      site {
        data: siteMetadata {
          menu {
            name
            to
          }
        }
      }
    }
  `)

  return (
    <div
      className={`${pathStyle} py-8 md:py-8 lg:px-4 fixed top-0 z-10 w-screen font-sans`}
    >
      <div className="container flex justify-between items-center">
        <Link to="/">
          <img alt="Logo" className="w-24 md:w-12 px-4 md:px-0" src="/logo.svg" />
        </Link>

        <button
          className="sm:hidden"
          onClick={() => setIsMenuOpen(true)}
          aria-label="Open Menu"
        >
          <FaBars className="h-6 w-auto fill-current -mt-1 text-mbr_orange mx-4" />
        </button>

        <div className={`hidden sm:block ${pathStyle}`}>
          {site.data.menu.map((link, key) => (
            <Link
              key={`menu_desktop_link${key}`}
              className=" ml-6 sm:ml-8 text-sm sm:text-base font-medium px-px border-b-2 pb-2 border-transparent text-mbr_blue hover:text-mbr_orange hover:font-bold transition duration-150 ease-in-out uppercase"
              activeClassName="text-mbr_orange hover:text-mbr_orange hover:bold"
              to={link.to}
            >
              {link.name}
            </Link>
          ))}
        </div>
      </div>
      <MenuMobile
        isOpen={isMenuOpen}
        setIsOpen={setIsMenuOpen}
        links={site.data.menu}
      />
    </div>
  )
}

export default Header
