import PropTypes from "prop-types"
import React from "react"
import "typeface-inter"
import "../styles/style.css"
import Footer from "../components/Footer"
import FooterNew from "../components/FooterNew"
import Header from "../components/Header"

const Layout = ({ children, path }) => {
  return (
    <>
      <Header path={path}/>
      {children}
      <FooterNew path={path}/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isHome: PropTypes.bool,
}

export default Layout
