import React from "react"
import { FaGithub } from "react-icons/fa"
import { Link } from "gatsby"

const FooterNew = () => {
  const year = new Date().getFullYear()
  return (
    <footer className="bg-mbr_blue h-56 w-full px-40 flex flex-col justify-center items-center text-white">
      <div className="flex lg:flex-row flex-col justify-center items-center w-full relative">
        <div className="flex items-baseline lg:absolute lg:left-0">
          <p className="large w-40 text-right sm:w-auto">Got work?</p>
          <h5 className="ml-1 w-40 sm:w-auto">Brief us</h5>
        </div>
        <a href='mailto:contact@madebyrise.com'>
          <h5 className="mb-4 lg:mb-0">contact@madebyrise.com</h5>
        </a>
        <div className="lg:absolute lg:right-0 text-2xl">
          <FaGithub />
        </div>
      </div>
      <div className="flex justify-center w-full absolute transform translate-y-20">
        <p className="text-sm opacity-75">© {year} MADE BY RISE</p>
      </div>
    </footer>
  )
}

export default FooterNew
