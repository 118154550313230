import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"

const SiteMetadata = ({ title, description, image }) => {
  const {
    site: {
      siteMetadata: { locale, siteTitle, siteKeywords, siteDescription },
    },
  } = useStaticQuery(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          locale
          siteTitle: title
          siteKeywords: keywords
          siteDescription: description,
        }
      }
    }
  `)
  return (
    <Helmet
      defer={false}
      defaultTitle={siteTitle}
      title={title}
      titleTemplate={`%s | ${siteTitle}`}
    >
      <html lang={locale} />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <meta name="description" content={`${description} | ${siteDescription}`} />
      <meta name="keywords" content={siteKeywords} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:locale" content={locale} />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:image" content={image} />
      <meta property="og:description" content={`${description} | ${siteDescription}` } />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:image" content={image} />
      <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.5.1/gsap.min.js"></script>

    </Helmet>
  )
}

SiteMetadata.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
}

SiteMetadata.defaultProps = {
  title: "Made By Rise",
  description: "Portfolio by MBR",
  image: null,
  image: "/social.png",
}

export default SiteMetadata
